body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contenedor-logo {
  display: flex;
  justify-content: center; /* Alineación horizontal al centro */
  align-items: left-right; /* Alineación vertical al centro */
  height: 10vh; /* Ajusta la altura del contenedor según tus necesidades */
}

.logo {
  width: 10px; 
  height: auto; /* Para mantener la proporción de aspecto */
}

.back-recommender-top {
  display: flex;
  justify-content: center; /* Alineación horizontal al centro */
  align-items: left-right; /* Alineación vertical al centro */
  height: 10vh; /* Ajusta la altura del contenedor según tus necesidades */
  background-color: rgba(215, 25, 33,255); /* Cambia el color de fondo a rojo */
  padding: 20px; /* Agrega espaciado interno para que el contenido no se vea pegado al borde */
  color: white; /* Cambia el color del texto a blanco */
  border-top-left-radius: 20px; /* Redondea la esquina superior izquierda */
  border-top-right-radius: 20px; /* Redondea la esquina superior derecha */
}

.back-recommender-bottom {
  display: flex;
  justify-content: center; /* Alineación horizontal al centro */
  align-items: left-right; /* Alineación vertical al centro */
  height: 10vh; /* Ajusta la altura del contenedor según tus necesidades */
  background-color: rgba(215, 25, 33,255); /* Cambia el color de fondo a rojo */
  padding: 20px; /* Agrega espaciado interno para que el contenido no se vea pegado al borde */
  color: white; /* Cambia el color del texto a blanco */
  border-bottom-left-radius: 20px; /* Redondea la esquina superior izquierda */
  border-bottom-right-radius: 20px; /* Redondea la esquina superior derecha */
}

.title {
  display: flex;
  justify-content: center; /* Alineación horizontal al centro */
  align-items: center; /* Alineación vertical al centro */
  height: auto;
}

red-background {
  background-color: rgba(215, 25, 33,255);
  padding: 10px; 
}
